import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { Row, Col, Typography } from "antd"

import Head from "../components/head"
import Layout from "../components/layout"
import BetaTestersLogos from "../components/beta-testers-logos"
import CtaRow from "../components/cta-row"
import HypothesesWithChart from "../components/hypotheses/hypotheses-with-chart"
import hypothesesData1 from '../data/hypotheses_chart_1.json'
import { CheckIcon, XIcon } from '@heroicons/react/solid'

const { Title, Paragraph, Text } = Typography

const BudgetPage = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      mainImage: file(relativePath: { eq: "logiciel-tableau-de-tresorerie.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      banksynchroImage: file(relativePath: { eq: "rocketchart-connect-bank-account-to-track-cash-in-and-cash-out-in-real-time.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      previsionsImage: file(relativePath: { eq: "anticiper-tresorerie-image-0.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      scenariosImage: file(relativePath: { eq: "scenarios-previsionnels.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      aurelienImage: file(relativePath: { eq: "aurelien-lovalente.png" }) {
        childImageSharp {
          gatsbyImageData(width: 100)
        }
      }
      michaelMichettiImage: file(relativePath: { eq: "michael-michetti.png" }) {
        childImageSharp {
          gatsbyImageData(width: 100)
        }
      }
      bastienTriclotImage: file(relativePath: { eq: "bastien-triclot.png" }) {
        childImageSharp {
          gatsbyImageData(width: 100)
        }
      }
      pierreImage: file(relativePath: { eq: "pierre-cathelin.png" }) {
        childImageSharp {
          gatsbyImageData(width: 100)
        }
      }
      studiomaticImage: file(relativePath: { eq: "studiomatic-logo.png" }) {
        childImageSharp {
          gatsbyImageData(height: 35)
        }
      }
      emSolarImage: file(relativePath: { eq: "em-solar-log.png" }) {
        childImageSharp {
          gatsbyImageData(height: 35)
        }
      }
      zozioImage: file(relativePath: { eq: "logo-zozio.png" }) {
        childImageSharp {
          gatsbyImageData(height: 35)
        }
      }
      hubvisoryImage: file(relativePath: { eq: "hubvisory-logo.png" }) {
        childImageSharp {
          gatsbyImageData(height: 35)
        }
      }
      arrowOneImage: file(relativePath: { eq: "arrow-down-1.png" }) {
        childImageSharp {
          gatsbyImageData(height: 70)
        }
      }
      arrowTwoImage: file(relativePath: { eq: "arrow-down-2.png" }) {
        childImageSharp {
          gatsbyImageData(height: 70)
        }
      }
      arrowThreeImage: file(relativePath: { eq: "arrow-down-3.png" }) {
        childImageSharp {
          gatsbyImageData(height: 90)
        }
      }
    }
  `)

  const description = "Automatisez la création et la gestion de votre budget de trésorerie. Remplacez votre suivi Excel par un logiciel de trésorerie fiable et en temps réel."

  return (
    <Layout noHeader>
      <Head
        title="Tableau de trésorerie"
        description={description}
        canonical="https://rocketchart.co/logiciel-budget-de-tresorerie/"
      >
      </Head>

      <Row className="content-container space-around-row" gutter={40} type="flex" align="middle" style={{ marginRight: 0, marginLeft: 0 }}>
        <Col xs={24} lg={10}>
          <Title>Oubliez votre budget de trésorerie Excel laborieux !</Title>
          <Paragraph style={{ color: "black" }}>Gagnez du temps et de la visibilité avec un suivi de trésorerie automatisé. Réalisez des prévisions fiables pour anticiper et utilisez les scénarios pour prendre les bonnes décisions.</Paragraph>
          <CtaRow />
        </Col>
        <Col xs={24} lg={14} justify="center">
          <GatsbyImage image={data.mainImage.childImageSharp.gatsbyImageData} alt="Remplacez les tableaux de trésorerie par un logiciel de trésorerie" />
        </Col>
      </Row>

      <span className='border-2 border-dashed rounded border-gray-200 w-full lg:w-96 my-16' />

      <Row className="content-container" type="flex" justify="center">
        <Col span={24} align='center' style={{ marginTop: "20px" }}>
          <Title level={2}>Comment ça fonctionne ?</Title>
        </Col>
      </Row>

      <Row className="content-container space-around-row" gutter={40} type="flex" align="middle">
        <Col xs={{ span: 24, order: 1 }} lg={{ span: 12, order: 0 }}>
          <GatsbyImage image={data.banksynchroImage.childImageSharp.gatsbyImageData} alt="Connectez vos comptes bancaires pour gérer votre trésorerie en temps réel." />
        </Col>
        <Col xs={{ span: 24, order: 0 }} lg={{ span: 12, order: 1 }}>
          <Title level={3}><span className='rc-highlight-text'>Gagnez du temps</span> avec la synchronisation bancaire sécurisée</Title>

          <div>
            <div className='flex flex-row items-start'>
              <div className='rounded-full p-0.5 bg-light-success mr-2 mt-0.5 h-auto'><CheckIcon className='h-5 w-5 text-success' /></div>
              <Paragraph style={{ color: "black" }}>Connectez tous vos comptes bancaires en temps réel pour automatiser le suivi de votre trésorerie</Paragraph>
            </div>

            <div className='flex flex-row items-start'>
              <div className='rounded-full p-0.5 bg-light-success mr-2 mt-0.5 h-auto'><CheckIcon className='h-5 w-5 text-success' /></div>
              <Paragraph style={{ color: "black" }}>Visualisez l’évolution de votre trésorerie consolidée sur une interface claire et intuitive</Paragraph>
            </div>

            <div className='flex flex-row items-start'>
              <div className='rounded-full p-0.5 bg-light-error mr-2 mt-0.5 h-auto'><XIcon className='h-5 w-5 text-error' /></div>
              <Paragraph style={{ color: "black" }}>Finis les exports manuels des relevés bancaires !</Paragraph>
            </div>
          </div>
        </Col>
      </Row>

      <Row className="content-container space-around-row" gutter={40} type="flex" align="middle">
        <Col xs={24} lg={12}>
          <Title level={3}><span className='rc-highlight-text'>Gagnez en visibilité</span> avec des prévisions de trésorerie fiables</Title>

          <div>
            <div className='flex flex-row items-start'>
              <div className='rounded-full p-0.5 bg-light-success mr-2 mt-0.5 h-auto'><CheckIcon className='h-5 w-5 text-success' /></div>
              <Paragraph style={{ color: "black" }}>Établissez facilement vos budgets prévisionnels de trésorerie à 1, 3, 6 ou 12 mois</Paragraph>
            </div>

            <div className='flex flex-row items-start'>
              <div className='rounded-full p-0.5 bg-light-success mr-2 mt-0.5 h-auto'><CheckIcon className='h-5 w-5 text-success' /></div>
              <Paragraph style={{ color: "black" }}>Synchronisez vos factures pour créer des prévisions précises sur les prochaines semaines</Paragraph>
            </div>

            <div className='flex flex-row items-start'>
              <div className='rounded-full p-0.5 bg-light-error mr-2 mt-0.5 h-auto'><XIcon className='h-5 w-5 text-error' /></div>
              <Paragraph style={{ color: "black" }}>Finis le rapprochement laborieux avec la facturation !</Paragraph>
            </div>
          </div>
        </Col>
        <Col xs={24} lg={12}>
          <GatsbyImage image={data.previsionsImage.childImageSharp.gatsbyImageData} alt="Anticiper facilement sa trésorerie prévisionnelle" />
        </Col>
      </Row>

      <Row className="content-container space-around-row" gutter={40} type="flex" align="middle">
        <Col xs={{ span: 24, order: 1 }} lg={{ span: 12, order: 0 }}>
          <GatsbyImage image={data.scenariosImage.childImageSharp.gatsbyImageData} alt="Réalisez des scénarios pour prendre les bonnes décisions" />
        </Col>
        <Col xs={{ span: 24, order: 0 }} lg={{ span: 12, order: 1 }}>
          <Title level={3}><span className='rc-highlight-text'>Prenez les bonnes décisions</span> grâce aux scénarios prévisionnels</Title>

          <div>
            <div className='flex flex-row items-start'>
              <div className='rounded-full p-0.5 bg-light-success mr-2 mt-0.5 h-auto'><CheckIcon className='h-5 w-5 text-success' /></div>
              <Paragraph style={{ color: "black" }}>Simulez vos hypothèses business dans différents scénarios pour éclairer vos prises de décisions</Paragraph>
            </div>

            <div className='flex flex-row items-start'>
              <div className='rounded-full p-0.5 bg-light-success mr-2 mt-0.5 h-auto'><CheckIcon className='h-5 w-5 text-success' /></div>
              <Paragraph style={{ color: "black" }}>Changez vos hypothèses : prêts, recrutements, retards de paiement, investissements, et anticipez les répercussions sur votre trésorerie</Paragraph>
            </div>

            <div className='flex flex-row items-start'>
              <div className='rounded-full p-0.5 bg-light-error mr-2 mt-0.5 h-auto'><XIcon className='h-5 w-5 text-error' /></div>
              <Paragraph style={{ color: "black" }}>Finis les prises de décisions à l'aveugle !</Paragraph>
            </div>
          </div>
        </Col>
      </Row>

      <span className='border-2 border-dashed rounded border-gray-200 w-full lg:w-96 my-16' />

      {/* HYPOTHESES */}
      <Row className="content-container" type="flex" justify="center">
        <Col span={24}>
          <div align='center'>
            <GatsbyImage image={data.arrowTwoImage.childImageSharp.gatsbyImageData} align='center' alt="arrow down 2" />
          </div>
        </Col>

        <Col xs={24} lg={12} className='text-center mt-8 mb-10'>
          <Title level={2}>Pourquoi est-ce <span className='rc-highlight-text'>plus efficace</span> qu'un budget de trésorerie Excel ?</Title>
        </Col>
      </Row>
      <HypothesesWithChart jsonData={hypothesesData1} className="content-container mt-8" />

      <span className='border-2 border-dashed rounded border-gray-200 w-full lg:w-96 my-16' />

      <Row className="content-container" type="flex" justify="center">
        <Col span={24}>
          <div align='center'>
            <GatsbyImage image={data.arrowThreeImage.childImageSharp.gatsbyImageData} align='center' alt="arrow down 3" />
          </div>
        </Col>
        <Col xs={24} lg={16} align='center' style={{ marginTop: '20px' }}>
          <Title level={2}>Prêt à gagner du temps et de la visibilité ?</Title>
          <div className='flex flex-col items-center'><CtaRow /></div>
        </Col>
      </Row>

      <span className='border-2 border-dashed rounded border-gray-200 w-full lg:w-96 my-16' />

      {/* TEMOIGNAGES */}
      <Row className="content-container" type="flex" justify="center">
        <Col span={24}>
          <div align='center'>
            <GatsbyImage image={data.arrowOneImage.childImageSharp.gatsbyImageData} align='center' alt="arrow down" />
          </div>
        </Col>
        <Col xs={24} lg={12} align='center' className='text-center mt-8 mb-8'>
          <Title level={2}>Ils ont déjà troqué leur budget de trésorerie Excel contre un suivi <span className='rc-highlight-text'>fiable & automatisé</span></Title>
        </Col>
      </Row>

      <Row className="content-container" gutter={40} type="flex">
        <Col xs={24} lg={12}>
          <div className="rc-quote-container" style={{ marginTop: 20, marginBottom: 20 }}>
            <div style={{ marginBottom: "10px", marginRight: "10px" }}>
              <GatsbyImage image={data.emSolarImage.childImageSharp.gatsbyImageData} alt="logo EM Solar" />
            </div>
            <Title level={4}>« Ne cherchez plus, ils l'ont fait - simplement BRAVO !! Fini Excel et fini le temps infini à passer pour gérer sa trésorerie et ses prévisions. RocketChart est très agréable, facile d'utilisation et comporte toutes les fonctionnalités attendues pour une bonne gestion de trésorerie. »</Title>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <GatsbyImage image={data.michaelMichettiImage.childImageSharp.gatsbyImageData} alt="Michael Michetti - président de EM Solar" />
              <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "10px" }}>
                <Text strong>Michael Michetti</Text>
                <Text>Président - EM Solar</Text>
              </div>
            </div>
          </div>
        </Col>

        <Col xs={24} lg={12}>
          <div className="rc-quote-container" style={{ marginTop: 20, marginBottom: 20 }}>
            <div style={{ marginBottom: "10px", marginRight: "10px" }}>
              <GatsbyImage image={data.hubvisoryImage.childImageSharp.gatsbyImageData} alt="logo Hubvisory" />
            </div>
            <Title level={4}>« RocketChart est un outil efficace pour suivre sa trésorerie. Il s'adapte bien à notre business model grâce aux catégories personnalisées et nous avons une bonne vue sur la performance de la société. Les scénarios nous permettent d'anticiper les impacts de nos choix pour prendre les bonnes décisions. »</Title>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <GatsbyImage image={data.pierreImage.childImageSharp.gatsbyImageData} alt="Pierre Cathelin - co-fondateur de Hubvisory" />
              <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "10px" }}>
                <Text strong>Pierre Cathelin</Text>
                <Text>Co-fondateur - Hubvisory</Text>
              </div>
            </div>
          </div>
        </Col>
      </Row>

      <Row className="content-container" gutter={40} type="flex">
        <Col xs={24} lg={12}>
          <div className="rc-quote-container" style={{ marginTop: 20, marginBottom: 20 }}>
            <div style={{ marginBottom: "10px", marginRight: "10px" }}>
              <GatsbyImage image={data.studiomaticImage.childImageSharp.gatsbyImageData} alt="logo Studiomatic" />
            </div>
            <Title level={4}>« RocketChart me permet d’avoir une vision instantanée sur l’état de ma trésorerie, et m’aide à prendre rapidement les bonnes décisions grâce aux scénarios prévisionnels de trésorerie : lorsqu’on doit arbitrer un investissement ou une dépense particulière. Le gain de temps est inestimable ! »</Title>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <GatsbyImage image={data.aurelienImage.childImageSharp.gatsbyImageData} alt="Aurelien Lovalente - Co-fondateur de Studiomatic" />
              <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "10px" }}>
                <Text strong>Aurelien Lovalente</Text>
                <Text>Co-fondateur - Studiomatic</Text>
              </div>
            </div>
          </div>
        </Col>

        <Col xs={24} lg={12}>
          <div className="rc-quote-container" style={{ marginTop: 20, marginBottom: 20 }}>
            <div style={{ marginBottom: "10px", marginRight: "10px" }}>
              <GatsbyImage image={data.zozioImage.childImageSharp.gatsbyImageData} alt="logo Zozio" />
            </div>
            <Title level={4}>« Avec Excel nous étions incapables de gérer notre trésorerie proprement. Avec RocketChart, nous sommes désormais capables de piloter en temps réel notre trésorerie, de projeter différents scenarii et de prévenir d'éventuels problèmes. Un outil rapidement déployé, efficace et ergonomique ! »</Title>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <GatsbyImage image={data.bastienTriclotImage.childImageSharp.gatsbyImageData} alt="Bastien Triclot - CEO & Fondateur de Zozio" />
              <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "10px" }}>
                <Text strong>Bastien Triclot</Text>
                <Text id='fonctionnalites'>CEO & Fondateur - Zozio</Text>
              </div>
            </div>
          </div>
        </Col>
      </Row>

      <Row className="content-container" gutter={40} type="flex" justify="center" style={{ marginTop: "30px" }}>
        <Col span={24} align='center'>
          <Text strong style={{ color: '#6a737d' }}>Ils gagnent du temps et de la visibité grâce à RocketChart</Text>
        </Col>
        <BetaTestersLogos />
      </Row>

      <span className='border-2 border-dashed rounded border-gray-200 w-full lg:w-96 my-16' />

      <iframe loading="lazy" width="560" height="315" className='max-w-full' title="Bienvenue !" src="https://www.youtube.com/embed/7JzhQHwnWLI?rel=0" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>

      <Row className="content-container" type="flex" justify="center" style={{ marginTop: "50px", marginBottom: "0px" }}>
        <Col span={24}>
          <div align='center'>
            <GatsbyImage image={data.arrowThreeImage.childImageSharp.gatsbyImageData} align='center' alt="arrow down 3" />
          </div>
        </Col>
        <Col xs={24} lg={16} align='center' style={{ marginTop: '20px', marginBottom: 100 }}>
          <Title level={2}>Prêt à gagner du temps et de la visibilité ?</Title>
          <div className='flex flex-col items-center'><CtaRow /></div>
        </Col>
      </Row>

    </Layout>
  )
}

export default BudgetPage